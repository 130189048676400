<template>
  <div class="section">
    <TableUser :get-data="getData" :detail-result="detailResult" :addUser="addUser" :result="result"
      :edit-item="editItem" @filterBranch="getFilter" />
    <Modal :get-data="getData" :result="result" :detail-result="detailResult" :form-data="formPayload"
      :edit-item="editItem" :create-item="createItem" :update-item="updateItem" :editId="editId"
      :cleanUpForm="cleanUpForm" />
  </div>
</template>

<script>
import moment from "moment";
import _ from "lodash";
import HeaderPage from "@/components/HeaderPage.vue";
import TableUser from "@/components/User/TableUser.vue";
import Modal from "@/components/User/Modal/ModalUsers.vue";

import {
  checkPermission,
  successNotification,
  errorNotification,
} from "@/auth/utils";

export default {
  title() {
    return "Gudang";
  },
  components: {
    HeaderPage,
    TableUser,
    Modal,
  },
  data() {
    return {
      filter: {
        search: "",
        per_page: 10,
        sort_type: "desc",
      },
      formPayload: {
        email: "",
        password: "",
        password_confirmation: "",
        branch_uuid: "",
        phone: "",
        phone_country: "",
        renewal_status: "",
        renewal_end_date: "",
        roles: [],
        permissions: [],
        selectedCountry: {
          country_code: "ID",
          country_flag: "https://flagsapi.com/ID/shiny/64.png",
          country_name: "Indonesia",
          dial_code_preview: "+62",
          dial_code_value: "62",
        },
      },
      currentPage: 1,
      isLoading: true,
      result: {},
      detailResult: {},
      validations: [],
      editId: null,
    };
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    };
  },
  created() {
    this.getData();
  },
  watch: {
    filter: {
      handler(value) {
        this.debounceGetData();
      },
      deep: true,
    },
  },
  methods: {
    getFilter(value) {
      this.filter = value;
    },
    addUser() {
      this.editId = null;
      this.formPayload = {
        email: "",
        password: "",
        password_confirmation: "",
        branch_uuid: "",
        phone: "",
        phone_country: "",
        renewal_status: "",
        renewal_end_date: "",
        roles: [],
        permissions: [],
        selectedCountry: {
          country_code: "ID",
          country_flag: "https://flagsapi.com/ID/shiny/64.png",
          country_name: "Indonesia",
          dial_code_preview: "+62",
          dial_code_value: "62",
        },
      };
      this.$bvModal.show("modal-user");
    },
    getPayload(value) {
      this.formPayload = value;
    },
    cleanUpForm() {
      this.editId = null;
      this.formPayload = {
        email: "",
        password: "",
        password_confirmation: "",
        branch_uuid: "",
        renewal_status: "",
        renewal_end_date: "",
        roles: [],
        permissions: [],
        selectedCountry: {
          country_code: "ID",
          country_flag: "https://flagsapi.com/ID/shiny/64.png",
          country_name: "Indonesia",
          dial_code_preview: "+62",
          dial_code_value: "62",
        },
      };
    },
    async getData(page = 1) {
      this.isLoading = true;
      const queryParams = this.filter;
      queryParams.page = page;
      this.$store
        .dispatch("merchant/getData", { uuid: "", params: queryParams })
        .then((result) => {
          this.isLoading = false;
          this.result = result.data.data;
          this.currentPage = result.data.data.current_page;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    debounceGetData: _.debounce(function (page = 1) {
      this.getData(page);
    }, 300),
    editItem(id) {
      this.cleanUpForm();
      this.editId = id;
      this.$store
        .dispatch("merchant/getData", {
          uuid: id,
          params: "",
        })
        .then((result) => {
          this.isLoading = false;
          this.formPayload = {
            name: result.data.data.name,
            email: result.data.data.email,
            branch_uuid: result.data.data.branch,
            phone: result.data.data.phone,
            roles: result.data.data.roles,
            renewal_status: result.data.data.renewal_status,
            renewal_end_date: moment(result.data.data.renewal_end_date).format(
              "YYYY-MM-DD"
            ),
            permissions: result.data.data.permissions,
            selectedCountry: result.data.data.phone_country,
          };
          this.$store.commit("merchant/setFormPayload", {
            name: result.data.data.name,
            email: result.data.data.email,
            branch_uuid: result.data.data.branch,
            phone: result.data.data.phone,
            roles: result.data.data.roles,
            renewal_status: result.data.data.renewal_status,
            renewal_end_date: moment(result.data.data.renewal_end_date).format(
              "YYYY-MM-DD"
            ),
            permissions: result.data.data.permissions,
            selectedCountry: result.data.data.phone_country,
          });
          this.$bvModal.show("modal-user");
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    async createItem() {
      const form = this.preparePayload();
      this.isLoading = true;
      this.$store
        .dispatch("merchant/postData", {
          params: "",
          payload: form,
        })
        .then(async () => {
          successNotification(this, "Success", "User berhasil di buat");
          this.cleanUpForm();
          this.$bvModal.hide("modal-user");
          this.isLoading = false;
          // window.location.href = "/users";
          await this.getData();
        })
        .catch((error) => {
          if (error.response.data.meta.messages.length > 0) {
            errorNotification(this, "Oops!", error.response.data.meta.messages);
          }
          this.isLoading = false;
        });
    },
    async updateItem() {
      const form = this.preparePayload();
      this.isLoading = true;
      form.append("_method", "PATCH");
      this.$store
        .dispatch("merchant/postData", {
          params: `/${this.editId}`,
          payload: form,
        })
        .then(async () => {
          await this.getData();
          successNotification(this, "Success", "User berhasil di ubah!");
          this.cleanUpForm();
          this.isLoading = false;
          this.$bvModal.hide("modal-user");
          // window.location.href = '/master/users'
        })
        .catch((error) => {
          if (error.response.data.meta.validations) {
            errorNotification(this, "Oops!", error.response.data.meta.messages);
          }
          this.isLoading = false;
        });
    },
    preparePayload() {
      this.formPayload.selectedCountry != null
        ? (this.formPayload.phone_country =
          this.formPayload.selectedCountry.country_code)
        : "";
      const form = new FormData();
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.formPayload) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.formPayload.hasOwnProperty(key) && this.formPayload[key]) {
          if (
            key !== "branch_uuid" &&
            key !== "roles" &&
            key !== "permissions" &&
            key != "selectedCountry"
          ) {
            form.append(key, this.formPayload[key]);
          }
          if (key === "branch_uuid") {
            form.append(key, this.formPayload[key].uuid);
          }
        }
      }
      // Prepare role payload
      this.formPayload.roles.forEach((item) => {
        form.append("roles[]", item);
      });

      // Prepare permissions payload
      this.formPayload.permissions.forEach((item) => {
        form.append("permissions[]", item);
      });
      return form;
    },
    // getDetail(id) {
    //   this.$store
    //     .dispatch("merchant/getDetail", {
    //       uuid: id || "",
    //       params: "",
    //     })
    //     .then((result) => {
    //       this.detailResult = result.data.data;
    //       this.formPayload = result.data.data;
    //     })
    //     .catch((err) => {
    //       // eslint-disable-next-line no-console
    //       console.log(err);
    //     });
    // },
  },
};
</script>
